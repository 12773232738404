import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { Snackbar } from '@material-ui/core';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';
import { Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        color: '#434343',
        fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
        fontSize: 14,
        backgroundColor: '#eeeeee',
        marginTop: theme.spacing(10),
    },
    container: {
        maxWidth: 400,
        padding: theme.spacing(4),
        boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
        backgroundColor: '#ffffff',
        borderRadius: 10,
        textAlign: 'center',
    },
    header: {
        color: '#0ee6b1',
        fontSize: 35,
        marginBottom: theme.spacing(2),
    },
    subHeader: {
        color: '#336859',
        fontSize: 30,
        marginBottom: theme.spacing(4),
    },
    fieldGroup: {
        marginBottom: theme.spacing(2),
    },
    button: {
        backgroundColor: '#00E1AA',
        color: '#ffffff',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        padding: theme.spacing(1, 4),
        '&:hover': {
            backgroundColor: '#00c196',
        },
    },
}));


const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};


export default function EditarEquipamento() {

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const [insumos, setInsumos] = useState([]);
    const [insumoSelecionado, setInsumoSelecionado] = useState(null);


    const [modulosPgms, setModulosPgms] = useState(null);


    function handleSetInsumo(insumo) {
        setInsumoSelecionado(insumo);
    }

    const [moduloPgmSelecionado, setModuloPgmSelecionado] = useState(null);


    function returnDescricaoInsumo(insumo_) {

        if (flagBack === 0) {
            let insumo = insumo_.insumo;
            let textoComQuebraDeLinha = "Descrição Principal: " + insumo.descricao;

            if (insumo.variacoes && insumo.variacoes.length > 0) {
                textoComQuebraDeLinha += insumo.variacoes

                    .map((variacao, index) => (
                        `<br />*Variação-> Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                    )).join('');
            }

            var texto =

                //"<br />Marca/Modelo: " + insumo.marca + "/" + insumo.modelo +
                // "<br />Código Fornecedor: " + insumo.codigo_fornecedor +
                //"<br />Tipo: " + insumo.tipo +
                //"<br />Categoria: " + insumo.categoria +
                textoComQuebraDeLinha + "<br />Estoque: " + (Array.isArray(insumo_.itens_estoque) ? insumo_.itens_estoque.length : 0) + " unidades";
            return texto;
        } else {
            let textoComQuebraDeLinha = "Descrição Principal: " + insumo_.descricao;

            if (insumo_.variacoes && insumo_.variacoes.length > 0) {
                textoComQuebraDeLinha += insumo_.variacoes

                    .map((variacao, index) => (
                        `<br />*Variação-> Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo_.variacoes.length - 1 ? '\n' : ''}`
                    )).join('');
            }

            return textoComQuebraDeLinha;
        }
    }




    function returnDescricaoStatus(status_item) {
        var texto = "";

        if (status_item === 0) {
            texto = "Disponível";
        }
        else if (status_item === 1) {
            texto = "Alugado";
        }
        else if (status_item === 2) {
            texto = "Vendido";
        }
        else if (status_item === 3) {
            texto = "Emprestado";
        }

        return texto
    }



    function returnDescricaoItemEstoque(item) {

        return "ID: " + item.id_item_estoque + "<br />" +
            "Status: " + returnDescricaoStatus(item.status_item) + "<br />" +
            "Fornecedor: " + (item.item.cotacao.fornecedor.tipo_fornecedor === 0 ? (item.item.cotacao.fornecedor.nome + " " + item.item.cotacao.fornecedor.sobrenome) : item.item.cotacao.fornecedor.nome_fantasia) + "<br />";


    }







    const { flagBack } = useParams();
    const { idCliente } = useParams();
    const { idInstalacao } = useParams();
    const { idEquipamento } = useParams();



    //const { idEquipamento } = useParams();


    const handleChangeIndex = (index) => {
        setValue(index);
        if (index === 1) {
            setShowMenuConfigPgm(true);
        } else {
            setShowMenuConfigPgm(false);

        }
    };



    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 1) {
            setShowMenuConfigPgm(true);
        } else {
            setShowMenuConfigPgm(false);

        }
    }




    const [instalacao, setInstalacao] = useState(null);
    const [botaoClicado, setBotaoClicado] = useState(false);


    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [nomeMapa, setNomeMapa] = useState("");
    const [iconeMapa, setIconeMapa] = useState(0);

    const handleIconeMapa = (event) => {
        setIconeMapa(event.target.value);
    };


    function returnDescricaoModuloPgm(modulo) {
        var texto = "ID: " + modulo.id_modulo_pgm + "\n Código: " + modulo.codigo;
        return texto;
    }


    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };



    const history = useHistory();



    async function funcaoSalvar() {


        try {

            const cadastro = {
                latitude: latitude,
                longitude: longitude,
                nome_mapa: nomeMapa,
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/equipamentos/atualizar/' + idEquipamento, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {

                alert("Equipamento Atualizado");
                if (parseInt(flagBack) === 1) {
                    history.push({

                        pathname: "/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/" + idInstalacao + "/" + idCliente,
                    })
                }

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
                //setBotaoSalvarClickado(false);

            }

        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }



    const [clienteSelecionado, setClienteSelecionado] = useState();

    const [clientes, setClientes] = useState();

    const [loading, setLoading] = useState(true);


    const [itemEstoqueSelecionado, setItemEstoqueSelecionado] = useState(null);

    const [equipamento, setEquipamento] = useState(null);


    const handleCliente = (event, cliente) => {

        setClienteSelecionado(cliente);
    };



    function returnDescricaoCliente(cliente) {
        console.log("Descricao de cliente chamada!");
        var texto = "ID: " + cliente.id_cliente + " " +
            (cliente.tipo_cliente === 0 ? ("CPF: " + cliente.cpf) : ("CNPJ: " + cliente.cnpj)) + " " +
            (cliente.tipo_cliente === 0 ? (cliente.nome + " " + cliente.sobrenome) : cliente.nome_fantasia);

        return texto;
    }


    function returnDescricaoInstalacao(instalacao) {
        var texto = "ID_Integrador: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }



    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            let id_cliente = parseInt(idCliente);
            let id_instalacao = parseInt(idInstalacao);
            let flag = parseInt(flagBack);


            if (flag === 0) {
                await api.get("v1/protected/clientes/listarTodos", {
                    headers: headers
                }).then(function (response) {
                    setClientes(response.data)
                    setLoading(false);


                });


            } else if (flag === 1) {
                console.log("Flag é " + flag);

                setClientes([]);

                await api.get("/v1/protected/retornardadoscliente/" + id_cliente, {
                    headers: headers
                }).then(function (response) {
                    console.log("Dados de cliente retornado!");
                    setClienteSelecionado(response.data);

                    //listar instalacao
                    api.get("/v1/protected/cliente/instalacoes/listarporid/" + idInstalacao, {
                        headers: headers
                    }).then(function (response) {
                        setInstalacao(response.data);
                        console.log("Instalacao retornada!");

                        //listar o equipamento
                        api.get("v1/protected/equipamentos/listarporid/" + idEquipamento, {
                            headers: headers
                        }).then(function (response) {
                            console.log("Item estoque retornado!");

                            setEquipamento(response.data);

                            setLatitude(response.data.latitude);
                            setLongitude(response.data.longitude);
                            setNomeMapa(response.data.nome_mapa);

                            setInsumoSelecionado(response.data.item_estoque.item.insumo)
                            setItemEstoqueSelecionado(response.data.item_estoque);

                            if (response.data.item_estoque != null &&
                                response.data.item_estoque.item.insumo.tipo_equipamento_area_tecnica === 0
                            ) {
                                setModuloPgmSelecionado(response.data.modulo_pgm);
                            }
                            setLoading(false);

                        });




                    });
                });


            }

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar os Clientes: " + _err)
        }
    }

    useEffect(() => {

        listarInformacoes();


    }, []);



    function handleSetInstalacao(instalacao) {
        setInstalacao(instalacao);
    }


    const classes = useStyles();

    const [centralIP, setCentralIP] = useState('');
    const [centralPorta, setCentralPorta] = useState('');
    const [centralUsername, setCentralUsername] = useState('');
    const [centralPassword, setCentralPassword] = useState('');
    const [conexaoPrioridade, setConexaoPrioridade] = useState('0'); // '0' para Ethernet/Wifi, '1' para Wifi/Ethernet


    const [showMenuConfigPgm, setShowMenuConfigPgm] = useState(false);
    const [subMenuPgm, setSubMenuPgm] = useState(1);


    const [cornetaIP, setCornetaIp] = useState('');



    const [wifiSSID, setWifiSSID] = useState('');
    const [wifiPasswd, setWifiPasswd] = useState('');
    const [wifiIp, setWifiIp] = useState('');
    const [wifiSubMascara, setWifiSuBmascara] = useState('');
    const [wifiGateway, setWifiGateway] = useState('');


    const [ethIp, setEthiIp] = useState('');
    const [ethSubMascara, setEthSuBmascara] = useState('');
    const [ethGateway, setEthGateway] = useState('');


    const salvarConfiguracoesCentral = async () => {
        if (centralIP && centralPorta && centralUsername && centralPassword) {
            try {

                const cadastro = {
                    dado1: centralIP,
                    dado2: centralPorta,
                    dado3: centralUsername,
                    dado4: centralPassword,


                };
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulopgm/tarefas/atualizarconfigs/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/92";
                const response = await api.post(url, cadastro, { headers });

                if (response.data) {
                    setSnackbarMessage('Salvo e Comando Enviado a PGM. Aguarde pela Resposta!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                }

            } catch (error) {
                alert("Erro ao salvar as configurações.");
            }
        } else {
            alert("Revise as informações.");
        }
    };


    const salvarConfiguracoesCorneta = async () => {
        if (cornetaIP) {
            try {

                const cadastro = {
                    dado1: cornetaIP,


                };
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulopgm/tarefas/atualizarconfigs/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/117";
                const response = await api.post(url, cadastro, { headers });

                if (response.data) {
                    setSnackbarMessage('Salvo e Comando Enviado a PGM. Aguarde pela Resposta!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                }

            } catch (error) {
                alert("Erro ao salvar as configurações.");
            }
        } else {
            alert("Revise as informações.");
        }
    };



    const salvarConfiguracoesWifi = async () => {
        if (wifiSSID && wifiPasswd) {
            try {

                const cadastro = {
                    dado1: wifiSSID,
                    dado2: wifiPasswd
                };
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulopgm/tarefas/atualizarconfigs/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/115";
                const response = await api.post(url, cadastro, { headers });

                if (response.data) {
                    if(wifiIp && wifiIp.length > 10 && wifiGateway.length > 10 && wifiSubMascara.length > 10 ){
                    const cadastrar = {
                        dado1: wifiIp,
                        dado2: wifiGateway,
                        dado3: wifiSubMascara,
                    };

                

                    const url2 = `/v1/protected/modulopgm/tarefas/atualizarconfigs/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/118";
                    const response2 = api.post(url2, cadastrar, { headers });

                    if (response2.data) {
                        setSnackbarMessage('Salvo com Dados de Endereçamento e Comando Enviado a PGM. Aguarde pela Resposta!');
                        setSnackbarSeverity('success');
                        setSnackbarOpen(true);
                    }
                }else{
                    setSnackbarMessage('Salvo sem Dados de Endereçamento e Comando Enviado a PGM. Aguarde pela Resposta!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                }

                }

            } catch (error) {
                alert("Erro ao salvar as configurações.");
                console.log(error);
            }
        } else {
            alert("Revise as informações.");
        }
    };




    const salvarConfiguracoesEth = async () => {
        if (cornetaIP) {
            try {

                const cadastro = {
                    dado1: ethIp,
                    dado2: ethGateway,
                    dado3: ethSubMascara,


                };
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulopgm/tarefas/atualizarconfigs/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/116";
                const response = await api.post(url, cadastro, { headers });

                if (response.data) {
                    setSnackbarMessage('Salvo e Comando Enviado a PGM. Aguarde pela Resposta!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                }

            } catch (error) {
                alert("Erro ao salvar as configurações.");
            }
        } else {
            alert("Revise as informações.");
        }
    };


    const reiniciarDispositivo = async () => {

    };


    useEffect(() => {


        if (equipamento !== null) {
            if (equipamento !== undefined) {
                if (equipamento.tipo_equipamento_area_tecnica === 0) {
                    if (equipamento.modulo_pgm !== null) {
                        if (equipamento.modulo_pgm !== undefined) {
                            setCentralIP(equipamento.modulo_pgm.ip_central);
                            setCentralPorta(equipamento.modulo_pgm.porta_central);
                            setCentralUsername(equipamento.modulo_pgm.usuario_central);
                            setCentralPassword(equipamento.modulo_pgm.senha_central);

                            setCornetaIp(equipamento.modulo_pgm.ip_corneta);

                            //wifi
                            setWifiSSID(equipamento.modulo_pgm.ssid);
                            setWifiPasswd(equipamento.modulo_pgm.passwd);
                            setWifiIp(equipamento.modulo_pgm.ip_wifi);
                            setWifiGateway(equipamento.modulo_pgm.gateway_wifi);
                            setWifiSuBmascara(equipamento.modulo_pgm.submascara_wifi);
                            //eth
                            setEthiIp(equipamento.modulo_pgm.ip_eth);
                            setEthGateway(equipamento.modulo_pgm.gateway_eth);
                            setEthSuBmascara(equipamento.modulo_pgm.submascara_eth);

                        }
                    }
                }
            }
        }

    }, [equipamento]);




    return (

        <div>
            <NavegadorGestao
                BaseDados={true}
                Projetos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }} />


            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <Container >

                    <React.Fragment >
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >



                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                            style={{ width: '100%', margin: 0, padding: 0 }}
                                        >
                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                            {
                                                equipamento.tipo_equipamento_area_tecnica === 0 && (
                                                    <Tab label="Configurar PGM" style={{ backgroundColor: '#1C1C1C' }} />
                                                )
                                            }

                                        </Tabs>

                                    </AppBar>

                                </Box>
                            </Grid>





                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >



<Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={6}
                                >
                                    <Button style={{ margin: 5 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={salvar}
                                        disabled={botaoClicado}
                                    > Atualizar Equipamento  </Button>
                                </Grid>

                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >

                                    <Grid
                                        value={value}
                                        index={0}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >



                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={5}
                                            >

                                                <Autocomplete
                                                    id="select-ordem-servico"
                                                    options={flagBack === 0 ? clientes : []}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(cliente) => returnDescricaoCliente(cliente).replace(/<br \/>/g, '\n')}
                                                    value={clienteSelecionado}
                                                    disabled={flagBack === 0 ? false : true}
                                                    onChange={handleCliente}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cliente) => {
                                                            const cotacaoText = returnDescricaoCliente(cliente).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Cliente " multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoCliente(option) }} />
                                                    )}

                                                />
                                            </Grid>
                                        </Grid>




                                        {clienteSelecionado &&
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                                style={{ marginTop: 20 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={5}
                                                >

                                                    <Autocomplete
                                                        id="select-instalacao"
                                                        options={flagBack === 0 ? clienteSelecionado.instalacoes : []}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(instalacao) => returnDescricaoInstalacao(instalacao).replace(/<br \/>/g, '\n')}
                                                        value={instalacao}
                                                        disabled={flagBack === 0 ? false : true}
                                                        onChange={(e, v) => handleSetInstalacao(v)}
                                                        filterOptions={(options, { inputValue }) => {
                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                            return options.filter((instalacao) => {
                                                                const cotacaoText = returnDescricaoInstalacao(instalacao).toLowerCase();
                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                            });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Instalação " multiline minRows={3} />
                                                        )}
                                                        renderOption={(option) => (
                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoInstalacao(option) }} />
                                                        )}

                                                    />
                                                </Grid>
                                            </Grid>
                                        }



                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={8}
                                        >

                                            <Autocomplete
                                                id="select-insumo"
                                                options={flagBack === 0 ? insumos : []}
                                                getOptionLabel={(insumo) => returnDescricaoInsumo(insumo).replace(/<br \/>/g, '\n')}
                                                value={insumoSelecionado}
                                                disabled={flagBack === 0 ? false : true}
                                                getOptionSelected={(option, value) => true}
                                                onChange={(e, v) => setInsumoSelecionado(v)}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((insumo) => {
                                                        const insumoText = returnDescricaoInsumo(insumo).toLowerCase();
                                                        return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Insumo " multiline minRows={2} />
                                                )}
                                                renderOption={(option) => (
                                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoInsumo(option) }} />
                                                )}
                                            />
                                        </Grid>


                                        {insumoSelecionado &&

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-item-estoque"
                                                    options={(insumoSelecionado && flagBack === 0) ? insumoSelecionado.itens_estoque : []}
                                                    getOptionLabel={(itemEstoque) => returnDescricaoItemEstoque(itemEstoque).replace(/<br \/>/g, '\n')}
                                                    value={itemEstoqueSelecionado}
                                                    disabled={flagBack === 0 ? false : true}
                                                    getOptionSelected={(option, value) => true}
                                                    disabled={flagBack === 0 ? false : true}
                                                    onChange={(e, v) => setItemEstoqueSelecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((insumo) => {
                                                            const insumoText = returnDescricaoItemEstoque(insumo).toLowerCase();
                                                            return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Item do Estoque " multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoItemEstoque(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        }

                                        {insumoSelecionado && itemEstoqueSelecionado && (itemEstoqueSelecionado.item.insumo.tipo_equipamento_area_tecnica === 0) &&

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-modulo_pgm"
                                                    options={[]}
                                                    getOptionLabel={(modulo) => returnDescricaoModuloPgm(modulo).replace(/<br \/>/g, '\n')}
                                                    value={moduloPgmSelecionado}
                                                    disabled={flagBack === 0 ? false : true}
                                                    getOptionSelected={(option, value) => true}
                                                    onChange={(e, v) => setModuloPgmSelecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((insumo) => {
                                                            const insumoText = returnDescricaoModuloPgm(insumo).toLowerCase();
                                                            return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Módulo PGM " multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoModuloPgm(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        }

                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography style={{ margin: 15 }} >
                                                    <span style={{ fontSize: 22, textDecoration: 'underline' }}>Dados de Mapa: </span>
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12} spacing={2}
                                            >
                                                <Grid item xs={4}>
                                                    <TextField

                                                        id="nome_mapa"
                                                        variant="standard"
                                                        name="nome_mapa"
                                                        fullWidth
                                                        label="Nome Mapa"
                                                        value={nomeMapa}
                                                        onChange={e => setNomeMapa(e.target.value)}
                                                    />
                                                </Grid>

                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12} spacing={2}
                                            >
                                                <Grid item xs={4}>
                                                    <TextField

                                                        id="latitude"
                                                        variant="standard"
                                                        name="latitude"
                                                        fullWidth
                                                        label="Latitude"
                                                        value={latitude}
                                                        onChange={e => setLatitude(e.target.value)}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField

                                                        id="longitude"
                                                        variant="standard"
                                                        name="longitude"
                                                        fullWidth
                                                        label="Longitude"
                                                        value={longitude}
                                                        onChange={e => setLongitude(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>


                                        </Grid>


                                    </Grid>

                                    <Grid
                                        value={value}
                                        index={1}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >


                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={2}
                                            >
                                                <List style={{ width: '100%' }}>
                                                    <ListItem style={{}}>
                                                        <Button
                                                            onClick={(e) => setSubMenuPgm(1)}
                                                            variant="contained" fullWidth style={{ backgroundColor: '#008000', color: '#fff' }}>
                                                            Configurações do Cliente
                                                        </Button>
                                                    </ListItem>
                                                    <ListItem style={{}}>
                                                        <Button
                                                            onClick={(e) => setSubMenuPgm(4)}
                                                            variant="contained" fullWidth style={{ backgroundColor: '#008000', color: '#fff' }} >
                                                            Configurações da Central
                                                        </Button>
                                                    </ListItem>
                                                    <ListItem style={{}}>
                                                        <Button
                                                            onClick={(e) => setSubMenuPgm(5)}
                                                            variant="contained" fullWidth style={{ backgroundColor: '#008000', color: '#fff' }} >
                                                            Configurações da Corneta
                                                        </Button>
                                                    </ListItem>
                                                    <ListItem style={{}}>
                                                        <Button
                                                            onClick={(e) => setSubMenuPgm(6)}
                                                            variant="contained" fullWidth style={{ backgroundColor: '#008000', color: '#fff' }} >
                                                            Opções
                                                        </Button>
                                                    </ListItem>
                                                </List>
                                            </Grid>



                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={10}
                                            >


                                                {subMenuPgm === 1 && (

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={6}
                                                        spacing={2}
                                                        style={{ with: 200, height: 200 }}
                                                    >
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={3}
                                                        >
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                style={{ backgroundColor: 'green' }}
                                                                onClick={(e) => setSubMenuPgm(2)}
                                                            >
                                                                Wifi
                                                            </Button>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={3}
                                                        >
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                style={{ backgroundColor: 'blue' }}
                                                                onClick={(e) => setSubMenuPgm(3)}
                                                            >
                                                                Ethernet
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                )}


                                                {subMenuPgm === 2 && (
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={10}
                                                    >
                                                        <Typography variant="h2" style={{ textAlign: 'center', color: '#0ee6b1', fontSize: '35px' }}>
                                                            TITANIWM MÓDULO PGM WIFI/ETHERNET
                                                        </Typography>
                                                        <Typography variant="h3" style={{ textAlign: 'center', color: '#336859', fontSize: '30px', marginBottom: '40px' }}>
                                                            Configure Wifi
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            label="SSID"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={wifiSSID}
                                                            onChange={(e) => setWifiSSID(e.target.value)}
                                                            placeholder="Digite o SSID"
                                                        />


                                                        <TextField
                                                            fullWidth
                                                            label="Senha"
                                                            type="password"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={wifiPasswd}
                                                            onChange={(e) => setWifiPasswd(e.target.value)}
                                                            placeholder="Digite a senha do SSID"
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            label="Ip"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={wifiIp}
                                                            onChange={(e) => setWifiIp(e.target.value)}
                                                            placeholder="Digite o IP "
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            label="Sub Mascara de Rede"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={wifiSubMascara}
                                                            onChange={(e) => setWifiSuBmascara(e.target.value)}
                                                            placeholder="Digite a Mascara de Rede "
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            label="Gateway da Rede"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={wifiGateway}
                                                            onChange={(e) => setWifiGateway(e.target.value)}
                                                            placeholder="Digite o Gateway "
                                                        />


                                                        <Box mt={4}>
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                style={{ backgroundColor: '#00E1AA' }}
                                                                onClick={(e) => salvarConfiguracoesWifi()}
                                                            >
                                                                Salvar e Enviar a PGM
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                )}



                                                {subMenuPgm === 3 && (
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={10}
                                                    >
                                                        <Typography variant="h2" style={{ textAlign: 'center', color: '#0ee6b1', fontSize: '35px' }}>
                                                            TITANIWM MÓDULO PGM WIFI/ETHERNET
                                                        </Typography>
                                                        <Typography variant="h3" style={{ textAlign: 'center', color: '#336859', fontSize: '30px', marginBottom: '40px' }}>
                                                            Configure Ethernet
                                                        </Typography>


                                                        <TextField
                                                            fullWidth
                                                            label="Ip"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={ethIp}
                                                            onChange={(e) => setEthIp(e.target.value)}
                                                            placeholder="Digite o IP "
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            label="Sub Mascara de Rede"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={ethSubMascara}
                                                            onChange={(e) => setEthSubMascara(e.target.value)}
                                                            placeholder="Digite a Mascara de Rede "
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            label="Gateway da Rede"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={ethGateway}
                                                            onChange={(e) => setEthGateway(e.target.value)}
                                                            placeholder="Digite o Gateway "
                                                        />


                                                        <Box mt={4}>
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                style={{ backgroundColor: '#00E1AA' }}
                                                                onClick={(e) => salvarConfiguracoesEth()}
                                                            >
                                                                Salvar e Enviar a PGM
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                )}


                                                {subMenuPgm === 4 && (
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={10}
                                                    >
                                                        <Typography variant="h2" style={{ textAlign: 'center', color: '#0ee6b1', fontSize: '35px' }}>
                                                            TITANIWM MÓDULO PGM WIFI/ETHERNET
                                                        </Typography>
                                                        <Typography variant="h3" style={{ textAlign: 'center', color: '#336859', fontSize: '30px', marginBottom: '40px' }}>
                                                            Configure Acesso a Central
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            label="Endereço IP da Central"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={centralIP}
                                                            onChange={(e) => setCentralIP(e.target.value)}
                                                            placeholder="Digite o endereço da central, ex: 10.0.0.1"
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            label="Porta da Central"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={centralPorta}
                                                            onChange={(e) => setCentralPorta(e.target.value)}
                                                            placeholder="Digite a porta da central, ex: 80"
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            label="Usuário"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={centralUsername}
                                                            onChange={(e) => setCentralUsername(e.target.value)}
                                                            placeholder="Digite o usuário de acesso"
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            label="Senha"
                                                            type="password"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={centralPassword}
                                                            onChange={(e) => setCentralPassword(e.target.value)}
                                                            placeholder="Digite a senha de acesso"
                                                        />
                                                        <Box mt={4}>
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                style={{ backgroundColor: '#00E1AA' }}
                                                                onClick={(e) => salvarConfiguracoesCentral()}
                                                            >
                                                                Salvar e Enviar a PGM
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                )}


                                                {subMenuPgm === 5 && (
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={10}
                                                    >
                                                        <Typography variant="h2" style={{ textAlign: 'center', color: '#0ee6b1', fontSize: '35px' }}>
                                                            TITANIWM MÓDULO PGM WIFI/ETHERNET
                                                        </Typography>
                                                        <Typography variant="h3" style={{ textAlign: 'center', color: '#336859', fontSize: '30px', marginBottom: '40px' }}>
                                                            Configure Acesso a Corneta
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            label="Endereço IP da Corneta"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={cornetaIP}
                                                            onChange={(e) => setCornetaIp(e.target.value)}
                                                            placeholder="Digite o endereço da corneta, ex: 10.0.0.1"
                                                        />


                                                        <Box mt={4}>
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                style={{ backgroundColor: '#00E1AA' }}
                                                                onClick={(e) => salvarConfiguracoesCorneta()}
                                                            >
                                                                Salvar e Enviar a PGM
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                )}

                                                {subMenuPgm === 6 && (
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={10}
                                                    >
                                                        <Typography variant="h2" style={{ textAlign: 'center', color: '#0ee6b1', fontSize: '35px' }}>
                                                            TITANIWM MÓDULO PGM WIFI/ETHERNET
                                                        </Typography>
                                                        <Typography variant="h3" style={{ textAlign: 'center', color: '#336859', fontSize: '30px', marginBottom: '40px' }}>
                                                            Opções Gerais
                                                        </Typography>



                                                        <Box mt={4}>
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                style={{ backgroundColor: 'red', color: 'white' }}
                                                                onClick={(e) => reiniciarDispositivo()}
                                                            >
                                                                Reiniciar
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                )}

                                            </Grid>

                                        </Grid>

                                    </Grid>


                                </SwipeableViews>


                            </Grid>

                        </Grid>


                    </React.Fragment>


                </Container >
            }


            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    style: {
                        backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336',
                        marginTop: '60px', // Ajuste a altura conforme necessário
                        fontSize: '16px',
                        fontWeight: 'bold',
                        border: '1px solid black',
                        borderRadius: '4px',
                        padding: '10px',
                        zIndex: 555,
                        boxShadow: '0 2px 6px rgba(0,0,0,0.3)',

                    }
                }}
            />


        </div >
    );
}


