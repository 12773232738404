import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';

import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Box,
    Tooltip
} from '@mui/material';


import api from '../../../../services/api';
import NavegadorGestao from "../../../../components/NavBarGestao";

import EditIcon from '@mui/icons-material/Edit';

import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import TextField from '@material-ui/core/TextField';


import {
    MaterialReactTable,

} from 'material-react-table';




export default function Equipamentos() {



    const [loading, setLoading] = useState(true);
    const [objetos, setObjetos] = useState([]);


    const processarTipoEquipamento = (tipo) => {
        let texto = "";
        switch (tipo) {
            case 0:
                texto = "PGM";
                break;
            case 10:
                texto = "MEDIDOR DE CONSUMO";
                break;
            case 20:
                texto = "CONTROLADOR DE ACESSO";
                break;
            case 30:
                texto = "MÓDULO SONORO";
                break;
            case 40:
                texto = "FONTE VDC";
                break;
            case 70:
                texto = "CENTRAL";
                break;
            case 80:
                texto = "CÂMERA";
                break;
            case 90:
                texto = "SENSOR";
                break;
            case 100:
                texto = "DVR";
                break;
            case 110:
                texto = "NVR";
                break;
            default:
                texto = "DESCONHECIDO";
                break;
        }
        return texto;
    }

    const processarIdentificador = (item) => {
        const tipo = item.tipo_equipamento_area_tecnica;
        if(tipo === 0){
            return item.modulo_pgm.codigo;
        }
    }

    async function listarObjetos() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/equipamentos/listartodos", {
                headers: headers
            }).then(function (response) {

                const updatedData = response.data.map(item => {
                    // Modificar o atributo 'status'
                    item.status = item.status === 1 ? 'ATIVO' : 'DESATIVADO';

                    item.descricao_item = item.item_estoque.item.insumo.descricao;

                    item.tipo_equipamento = processarTipoEquipamento(item.tipo_equipamento_area_tecnica);

                    item.identificador = processarIdentificador(item);

                    return item;
                });


                setObjetos(updatedData);
                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar objetos: " + _err)

        }

    }


    useEffect(() => {




        listarObjetos();


    }, []);





    const handleClickEditarEquipamento = (row) => {
        window.location.href = "/gestao/administracao/equipamentos/editarequipamento/" + row.original.id_equipamento + "/1/0/0";
    };




    const columns = useMemo(
        () => [

            {
                accessorKey: 'id_equipamento', //normal accessorKey
                header: 'ID',
                size: 60,
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 50,
                Cell: ({ renderedCellValue }) => (
                    <span >
                        {renderedCellValue}
                    </span>
                ),
            },
            {
                accessorKey: 'descricao_item',
                header: 'Nome',
                size: 250,
                Cell: ({ renderedCellValue }) => (

                    <span >
                        {renderedCellValue}
                    </span>
                ),
            },
            {
                accessorKey: 'tipo_equipamento',
                header: 'Tipo',
                size: 150,
                Cell: ({ renderedCellValue }) => {

                    return <span>{renderedCellValue}</span>;
                },
            },
            {
                accessorKey: 'identificador',
                header: 'Identificador',
                size: 150,
                Cell: ({ renderedCellValue }) => {

                    return <span>{renderedCellValue}</span>;
                },
            },

        


        ],
        [],
    );



    return (
        <div>

            <NavegadorGestao
                BaseDados={true}
                Clientes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ padding: 5 }}>

                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        {objetos && (
                            <MaterialReactTable
                            columns={columns}
                            data={objetos}
                            enableGlobalFilterModes
                            enablePagination={true}
                            enableRowActions
                            paginationDisplayMode={'pages'}
                            getRowId={(row) => row.id_equipamento}
                            enableTopToolbar={true}
                            enableBottomToolbar={false}
                            enableColumnFilters={true}
                            enableColumnActions={false}
                            sortable={true}
                            enableSorting={true}
                            enableRowSelection={false}


                            enableColumnFilterModes={true}
                            enableExpandAll={true}
                            enableColumnPinning={true}
                            enableColumnResizing={true}




                            initialState={{
                                pagination: { pageSize: 50, pageIndex: 0 }, // Correção da sintaxe aqui
                                showGlobalFilter: true,
                                density: 'compact',
                            }}
                            muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                            muiPaginationProps={{
                                rowsPerPageOptions: [50, 100, 200],
                                variant: 'outlined',
                            }}
                            enableFullScreenToggle={true}
                            renderDetailPanel={({ row }) => (
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        left: '30px',
                                        maxWidth: '1000px',
                                        position: 'sticky',
                                        width: '100%',
                                    }}
                                >
                                </Box>
                            )}
                                renderRowActions={({ row }) => (
                                    <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>

                                        <Tooltip title="Gerenciar">
                                            <IconButton color="error" onClick={() => handleClickEditarEquipamento(row)}>
                                                <SettingsIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </Box>
                                )}
                            />
                        )}


                    </div>
                }


            </div>




        </div>
    );
}



